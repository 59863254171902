/* Open-source loader CSS from https://loading.io/css/ */

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-ellipsis {
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  position: absolute;
  width: 30px;
  height: 20px;
  z-index: 1;
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}
.lds-ellipsis div {
  position: absolute;
  top: 13px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 4px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 4px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 16px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 28px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}


@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

/* Wave Loader */

.wave-loader {
  justify-content: center;
	display: flex;
	align-items: center;
  height: 60px;
}
.wave-loader span {
	height: 0px;
  border-radius: 10px;
	width: 7px;
	margin-right: 10px;
	background-color: #324755;
  opacity: 0.1;
	animation: wave-loading 1.3s linear infinite;
}
.wave-loader span:nth-child(1){
	animation-delay: calc(0.1 * 1.3)s;
}
.wave-loader span:nth-child(2){
	animation-delay: calc(0.2 * 1.3s);
}
.wave-loader span:nth-child(3){
	animation-delay: calc(0.3 * 1.3s);
}
.wave-loader span:nth-child(4){
	animation-delay: calc(0.4 * 1.3s);
}
.wave-loader span:nth-child(5){
	animation-delay: calc(0.5 * 1.3s);
}
.wave-loader span:nth-child(6){
	animation-delay: calc(0.6 * 1.3s);
}
.wave-loader span:nth-child(7){
	animation-delay: calc(0.7 * 1.3s);
}
.wave-loader span:nth-child(8){
	animation-delay: calc(0.8 * 1.3s);
}
.wave-loader span:nth-child(9){
	animation-delay: calc(0.9 * 1.3s);
}
@keyframes wave-loading {
	0%{
    height: 0px;
    opacity: 0.1;
	}
	25%{
    height: 25px;
    /* width: 7px; */
    opacity: 1;
	}
	50%{
    height: 50px;
    /* width: 7px; */
    opacity: 1;
	}
	100%{
    height: 0px;
    /* width: 0px; */
    opacity: 0.1;
	}
}
